<template>
  <div class="comment">
    <el-card>
      <div class="comment_title">{{ activeName }}</div>
      <div class="comment_ul">
        <div class="comment_ul_li">姓名：{{ commentData.enrollName }}</div>
        <div class="comment_ul_li">学校：{{ commentData.school }}</div>
        <div class="comment_ul_li">年级：{{ commentData.grade }}</div>
        <div class="comment_ul_li">班级：{{ commentData.clazz }}</div>
        <div class="comment_ul_li">指导老师：{{ commentData.instructor }}</div>
        <div class="comment_ul_li">联系方式：{{ commentData.phone }}</div>
      </div>
      <div class="comment_img">
        <div class="comment_img_title">图片：</div>
        <div class="comment_img_div">
          <img
            @click="imgClick(item.images)"
            :src="item.images"
            alt=""
            v-for="(item, index) in commentData.enrollmentFileViews"
            :key="index"
          />
        </div>
      </div>
      <div class="comment_file" v-if="commentData.file">
        附件：<el-link :href="commentData.fileKey" type="primary">{{
          commentData.file
        }}</el-link>
      </div>
      <div class="comment_introduction">
        作品简介：{{ commentData.introduction }}
      </div>
      <div class="comment_icon">
        <i class="el-icon-edit"></i>
        点评
      </div>
      <div class="comment_prize">
        名次：<el-select v-model="queryInfo.workPlace" placeholder="请选择">
          <el-option
            v-for="(item, index) in options"
            :key="index"
            :label="item.label"
            :value="item.label"
          >
          </el-option>
        </el-select>
      </div>
      <div class="comment_score">
        <span>评分：</span><el-input v-model="queryInfo.score" type="number" />
      </div>
      <div class="comment_btn">
        <el-button @click="btn">提交</el-button>
      </div>
    </el-card>
    <el-dialog
      title="图片"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose"
    >
      <div class="diaImg">
        <img :src="imgSrc" />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { commetById, editComment, addComment } from "@/api/activity";
export default {
  name: "comment",
  computed: {
    // title
    activeName() {
      return this.$route.query.activeName;
    },
    //作品id
    commentId() {
      return this.$route.query.id;
    },
    //活动id
    activeId() {
      return this.$route.query.activeId;
    },
  },
  data() {
    return {
      imgSrc: "",
      dialogVisible: false,
      commentData: {},
      type: 0, //0---新增   1---编辑
      queryInfo: {
        score: "", //评分
        workPlace: "", //作品名次
        activeId: "", //活动id
        enrollmentId: "", //作品id
      }, //点评的分数
      options: [
        {
          label: "一等奖",
        },
        {
          label: "二等奖",
        },
        {
          label: "三等奖",
        },
      ],
    };
  },
  created() {
    this.getComentId();
  },
  methods: {
    //点击图片
    imgClick(src) {
      this.imgSrc = src;
      this.dialogVisible = true;
    },
    //关闭弹窗
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    //新增编辑按钮
    async btn() {
      if (this.type) {
        //新增
        this.queryInfo.activeId = this.activeId;
        this.queryInfo.enrollmentId = this.commentId;
        const { data: res } = await addComment(this.queryInfo);
        if (res.success) {
          this.$message.success("新增成功。");
          this.$router.go(-1);
        } else {
          this.$message.error("新增失败");
        }
      } else {
        //编辑
        const { data: res } = await editComment(this.queryInfo);
        if (res.success) {
          this.$message.success("编辑成功");
          this.$router.go(-1);
        } else {
          this.$message.error("编辑失败");
        }
      }
    },
    //获取作品详情
    async getComentId() {
      const { data: res } = await commetById({ id: this.commentId });
      if (res.success) {
        this.commentData = res.data;
        if (res.data.evaluationWork.activeId === 0) {
          //=0是新增
          //type = 1 编辑
          this.type = 1;
        } else {
          //新增
          this.type = 0;
          this.queryInfo = res.data.evaluationWork;
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.comment {
  width: 100%;
  overflow: hidden;

  // 全局css 加上以下代码，可以隐藏上下箭头
  // 取消input的上下箭头
  /deep/input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }

  /deep/input::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
  }

  /deep/input[type="number"] {
    -moz-appearance: textfield;
  }

  .comment_title {
    width: 100%;
    overflow: hidden;
    text-align: center;
    margin: 20px 0;
    color: #151515;
    font-size: 24px;
    font-weight: 500;
  }

  .comment_ul {
    width: 100%;
    margin: 10px 0;
    overflow: hidden;

    .comment_ul_li {
      color: #333333;
      font-size: 14px;
      font-weight: 400;
      margin-right: 70px;
      display: inline-block;
    }
  }

  .comment_img {
    margin: 20px 0;
    width: 100%;
    overflow: hidden;

    .comment_img_title {
      color: #333333;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 10px;
    }

    .comment_img_div {
      width: 100%;
      overflow: hidden;

      img {
        width: 100px;
        height: 100px;
        margin-right: 30px;
        cursor: pointer;
      }
    }
  }

  .comment_file {
    width: 100%;
    overflow: hidden;
    margin: 10px 0;
    color: #333333;
    font-size: 14px;
    font-weight: 400;
  }

  .comment_introduction {
    width: 100%;
    overflow: hidden;
    margin: 10px 0;
    color: #333333;
    font-size: 14px;
    font-weight: 400;
    box-sizing: border-box;
    padding-bottom: 40px;
    border-bottom: 1px dashed #eee;
  }

  .comment_icon {
    width: 100%;
    margin-top: 40px;
    color: #8483d0;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 30px;
  }

  .comment_prize {
    width: 100%;
    overflow: hidden;
    margin-bottom: 20px;
    color: #333333;
    font-size: 14px;
    font-weight: 400;
  }

  .comment_score {
    width: 100%;
    overflow: hidden;
    margin-bottom: 20px;
    color: #333333;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;

    /deep/ .el-input {
      width: auto !important;
    }

    /deep/ .el-input__inner {
      width: 200px !important;
    }
  }

  .comment_btn {
    margin-top: 40px;
  }

  .diaImg {
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    img {
      max-width: 100%;
      height: auto;
      max-height: 600px;
      overflow: hidden;
    }
  }
}
</style>